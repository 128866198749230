import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ConfigurationService,
  IAppSettings
} from 'proceduralsystem-clientcomponents';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, pluck, shareReplay, switchMap, tap } from 'rxjs/operators';

export interface AppConfig extends IAppSettings {
  defaultLang: string;
  WeekLongPdfUrl: string;
}

const cookieDays = 30;

@Injectable({ providedIn: 'root' })
export class AppConfigService {
  private localSettings: AppConfig;
  private config$: Observable<AppConfig> | null = null;
  private readonly values$ = new BehaviorSubject(null);

  constructor(
    private readonly http: HttpClient,
    private readonly configurationService: ConfigurationService<AppConfig>
  ) {}

  get<T extends keyof AppConfig>(prop: T): Observable<AppConfig[T]> {
    if (!this.config$) {
      this.config$ = this.fetchConfig();
    }

    return this.config$.pipe(pluck(prop));
  }

  getValue<T extends keyof AppConfig>(prop: T): AppConfig[T] {
    const config = this.values$.getValue();

    return config ? config[prop] : null;
  }

  set<T extends keyof AppConfig>(prop: T, value: string): void {
    const config = this.values$.getValue();
    config[prop] = value;

    this.config$ = of(config);
  }

  setCookie(property: string, value: string, days = cookieDays): void {
    this.configurationService.setCookie(property, value, days);
  }

  private fetchConfig(): Observable<AppConfig> {
    return this.fetchLocalSettings().pipe(
      switchMap(v => this.fetchBESettings()),
      map(v => Object.assign(v, this.localSettings)),
      tap(v => this.values$.next(v))
    );
  }

  private fetchLocalSettings(): Observable<AppConfig> {
    return this.http.get<AppConfig>('../../app.config.json').pipe(
      tap(v => (this.localSettings = v)),
      shareReplay(1)
    );
  }

  private fetchBESettings(): Observable<IAppSettings> {
    return this.configurationService.getAll(this.localSettings);
  }
}
