import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { AppConfigService } from './services/app-config.service';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {
  constructor(
    private readonly config: AppConfigService,
    private readonly router: Router,
    private readonly elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.config
      .get('VersionNumber')
      .pipe(take(1))
      .subscribe(() => {
        this.config.setCookie('lang', 'en');

        const navigateTo = this.elementRef.nativeElement.getAttribute(
          'navigateTo'
        );
        if (navigateTo) {
          this.router.navigate([navigateTo]).catch();
        }
      });
  }
}
