import { Routes, RouterModule } from '@angular/router';
import { PageComponent } from './page.component';
import { MainNavigation } from './shared/shared.enum';
import { MsalGuard } from '@azure/msal-angular';

const routes: Routes = [
  { path: '', redirectTo: MainNavigation.QUALITY_MANAGER, pathMatch: 'full' },
  {
    path: '',
    component: PageComponent,
    canActivate: [MsalGuard],
    children: [
      {
        path: MainNavigation.QUALITY_MANAGER,
        loadChildren: () =>
          import(
            '../../src/app/modules/quality-manager/quality-manager.module'
          ).then(esModule => esModule.QualityManagerModule)
      },
      {
        path: MainNavigation.DAIL_BUSINESS,
        loadChildren: () =>
          import(
            '../../src/app/modules/dail-business/dail-business.module'
          ).then(esModule => esModule.DailBusinessModule)
      },
      {
        path: MainNavigation.ADD_NEW_ITEM,
        loadChildren: () =>
          import('../../src/app/modules/add-new-item/add-new-item.module').then(
            esModule => esModule.AddNewItemModule
          )
      },
      {
        path: MainNavigation.ARCHIVE,
        loadChildren: () =>
          import('../../src/app/modules/archive/archive.module').then(
            esModule => esModule.ArchiveModule
          )
      }
    ]
  },
  { path: '**', redirectTo: MainNavigation.QUALITY_MANAGER, pathMatch: 'full' }
];

export const routing = RouterModule.forRoot(routes, {
  useHash: false
});
