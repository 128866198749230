import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AppConfigService } from './app-config.service';
import { OirAuthService, ToastNotificationsService } from 'proceduralsystem-clientcomponents';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  public data: Subject<string>;
  private connection: signalR.HubConnection;

  constructor(
    private readonly configurationService: AppConfigService,
    private readonly authService: OirAuthService,
    private toastNotificationsService: ToastNotificationsService,
    private readonly msalService: MsalService
  ) {}

  ngOnDestroy(): void {
    this.stopConnection();
  }

  /**
   * Create connection to signalR.
   */
  public createConnection(): void {
    this.authService.getAccessTokenByEndpoint('CommsEndpoint')
      .pipe(
        take(1),
        map(token => ({ signalrUrl: this.configurationService.get('SignalRConnectionUrl'), token }))
      )
      .subscribe(({ signalrUrl, token }) => {
        signalrUrl.pipe(
          take(1)
        ).subscribe(url => {
          // Add token to headers to authorize connection
          const headers = {
            Authorization: `Bearer ${token}`
          };
          const userName = this.configurationService.getValue('UserName');
          this.connection = new signalR.HubConnectionBuilder()
            .withUrl(`${url}?userName=${userName}`, { transport: signalR.HttpTransportType.LongPolling, headers })
            .withAutomaticReconnect()
            .build();
          this.startConnection();
        });
      });
  }

  /**
   * Stop connection to signalR and complete data stream
   * to unsubscribe from it.
   */
  public stopConnection(): void {
    this.connection
      .stop()
      .catch(err => console.log('error while stopping connection', err));
    this.data.complete();
  }

  /**
   * Start connection to signalR and subscribe
   * to Notify event to get notifications from server.
   */
  private startConnection(): void {
    this.connection
      .start()
      .catch(err => console.log('error while starting connection', err));
    this.connection.on('Notify', (updated: any) => {
      try {
        this.toastNotificationsService.addNotification({
          title: updated.title,
          description: updated.description,
        });
      } catch (error) {
        this.toastNotificationsService.addNotification({
          title: 'COMMON.REQUESTERROR',
          description: 'COMMON.REQUESTERRORDESCRIPTION'
        });
        if (error.status === 401) {
          this.renewToken();
        }
      }

      this.data.next(updated);
    });
  }

  /**
   * Renew access token. This method is called when the access token
   * is expired or the server returns a 401 Unauthorized response.
   */
  renewToken() {
    this.stopConnection();
    this.msalService.acquireTokenSilent({
      scopes: [this.configurationService.getValue('CommsEndpoint').scopes[0]],
    })
    .subscribe(() => {},
    (error) => {
      console.log('Error renewing access token:', error);
    });
  }
}
