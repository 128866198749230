import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService, Node } from 'proceduralsystem-clientcomponents';
import { CancelData } from '../modules/quality-manager/models/quality-manager.models';
import { DebounceTime } from '../shared/shared.enum';

export const QUALITY_MANAGER_NAV_ENTRY_NAME = 'Quality manager';
export const DAIL_BUSINESS_NAV_ENTRY_NAME = 'Dáil Business';
export const ADD_ITEM_NAV_ENTRY_NAME = 'Add new item';
export const ARCHIVE_NAV_ENTRY_NAME = 'Archive';
export const DAIL_BUSINESS_MANAGER_ENTRY_NAME = 'Dáil Business manager';

const TopLevelNavigationTitles = [
  QUALITY_MANAGER_NAV_ENTRY_NAME,
  DAIL_BUSINESS_NAV_ENTRY_NAME,
  ADD_ITEM_NAV_ENTRY_NAME,
  ARCHIVE_NAV_ENTRY_NAME
];

export enum ItemType {
  BILL,
  MOTION,
  STATEMENT,
  MONEY_MESSAGE,
  OTHER_ITEM
}

export enum ViewType {
  Quality_Manager = 'quality-manager',
  Dail_Business = 'dail-business',
  Archive = 'archive'
}

enum ItemTypeString {
  'bill',
  'motion',
  'statement',
  'money-message',
  'other-item'
}

@Injectable({ providedIn: 'root' })
export class OpNavigationService {
  constructor(
    private readonly navigationService: NavigationService,
    private readonly router: Router
  ) {}

  public navigateToQualityManager(stateExtras?: any): void {
    this.router.navigate(['quality-manager'], { state: stateExtras });
    this.clearQualityManagerNavigation();
  }

  public navigateToDailBusiness(stateExtras?: any): void {
    this.router.navigate(['dail-business'], { state: stateExtras });
    this.clearDailBusinessNavigation();
  }

  public navigateToAddNewItem(): void {
    this.router.navigate(['add-new-item']);
  }

  public navigateToArchive(stateExtras?: any): void {
    this.router.navigate(['archive'], { state: stateExtras });
    this.clearArchiveNavigation();
  }

  public navigateToOriginatingModule(url: string): void {
    window.open(url, '_blank');
  }

  /**
   * Initialize app navigation with main sections
   */
  public initNavigation(): void {
    this.navigationService.init({
      module: { title: DAIL_BUSINESS_MANAGER_ENTRY_NAME, path: '' },
      homeUrl: '/quality-manager',
      tree: [
        { title: QUALITY_MANAGER_NAV_ENTRY_NAME, path: '/quality-manager' },
        { title: DAIL_BUSINESS_NAV_ENTRY_NAME, path: '/dail-business' },
        { title: ADD_ITEM_NAV_ENTRY_NAME, path: '/add-new-item' },
        { title: ARCHIVE_NAV_ENTRY_NAME, path: '/archive' }
      ]
    });
  }

  /**
   * Clear Quality manager navigation section
   * removes children and selection
   */
  public clearQualityManagerNavigation(): void {
    if (this.navigationService.model && this.navigationService.currentNode) {
      this.navigationService.select(this.navigationService.currentNode);
      const qualityManagerNode = this.navigationService.model.tree.find(
        x => x.title === QUALITY_MANAGER_NAV_ENTRY_NAME
      );
      qualityManagerNode.expanded = false;
      qualityManagerNode.selected = false;
    }
  }

  /**
   * Add child node to Quality manager navigation section
   * @param node
   */
  public addQualityManagerNode(node: Node): void {
    setTimeout(() => {
      if (this.navigationService.model) {
        const qualityManagerNode = this.navigationService.model.tree.find(
          x => x.title === QUALITY_MANAGER_NAV_ENTRY_NAME
        );
        this.navigationService.select(qualityManagerNode);

        this.addNode(node);
      }
    }, DebounceTime.TWO_HUNDRED);
  }

  /**
   * Clear Dail business navigation section
   * removes children and selection
   */
  public clearDailBusinessNavigation(): void {
    if (this.navigationService.model && this.navigationService.currentNode) {
      this.navigationService.select(this.navigationService.currentNode);
      const dailBusinessNode = this.navigationService.model.tree.find(
        x => x.title === DAIL_BUSINESS_NAV_ENTRY_NAME
      );
      dailBusinessNode.expanded = false;
      dailBusinessNode.selected = false;
    }
  }

  /**
   * Add child node to Dail business navigation section
   * @param node
   */
  public addDailBusinessNode(node: Node): void {
    setTimeout(() => {
      if (this.navigationService.model) {
        const archiveNode = this.navigationService.model.tree.find(
          x => x.title === DAIL_BUSINESS_NAV_ENTRY_NAME
        );
        this.navigationService.select(archiveNode);

        this.addNode(node);
      }
    }, DebounceTime.TWO_HUNDRED);
  }

  /**
   * Clear Archive navigation section
   * removes children and selection
   */
  public clearArchiveNavigation(): void {
    if (this.navigationService.model && this.navigationService.currentNode) {
      this.navigationService.select(this.navigationService.currentNode);
      const archiveNode = this.navigationService.model.tree.find(
        x => x.title === ARCHIVE_NAV_ENTRY_NAME
      );
      archiveNode.expanded = false;
      archiveNode.selected = false;
    }
  }

  /**
   * Add child node to Archive navigation section
   * @param node
   */
  public addArchiveNode(node: Node): void {
    setTimeout(() => {
      if (this.navigationService.model) {
        const archiveNode = this.navigationService.model.tree.find(
          x => x.title === ARCHIVE_NAV_ENTRY_NAME
        );
        this.navigationService.select(archiveNode);

        this.addNode(node);
      }
    }, DebounceTime.TWO_HUNDRED);
  }

  /**
   * Add node to selected navigation section
   * @param node
   */
  public addNode(node: Node): void {
    this.navigationService.addNode(node);
  }

  public navigateToItem(
    itemType: ItemType,
    itemId: number,
    viewType: ViewType,
    itemTypeId?: number
  ): void {
    const route = this.getRouteByType(itemType);
    if (itemTypeId !== undefined && itemTypeId !== null) {
      this.router.navigate([viewType, route, itemTypeId, itemId]);
    } else {
      this.router.navigate([viewType, route, itemId]);
    }
  }

  public getRouteByType(typeNumber: ItemType): string {
    return ItemTypeString[+typeNumber];
  }

  public getNavigationData(tabId?: number, viewId?: number): CancelData {
    if (tabId && viewId) {
      return {
        selectedTabIndex: tabId,
        selectedSubTabIndex: viewId
      };
    } else if (tabId && !viewId) {
      return {
        selectedTabIndex: tabId,
        selectedSubTabIndex: null
      };
    }
  }

  clearExpandedChildNodes(activeTitle: string): void {
    if (
      TopLevelNavigationTitles.includes(activeTitle) &&
      this.navigationService.model
    ) {
      const expandedNodes = this.navigationService.model.tree.filter(
        x => x.expanded
      );
      expandedNodes.forEach(node => {
        node.children = [];
        node.expanded = false;
      });
    }
  }
}
