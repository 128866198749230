import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { PageComponent } from './page.component';
import { RouterModule } from '@angular/router';
import { routing } from './app-routing.module';
import { ClientFrameworkModule } from 'proceduralsystem-clientcomponents';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { HttpErrorInterceptor } from './services/http-error.interceptor';
import { APP_BASE_HREF } from '@angular/common';

import { AppConfigService } from "./shared/services/app-config.service";
import {
  EventType,
  InteractionType,
  PublicClientApplication,
} from "@azure/msal-browser";
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalRedirectComponent,
  MsalModule,
  MsalService,
  MsalGuard,
  MsalBroadcastService,
} from "@azure/msal-angular";
import { AuthenticationResult } from "@azure/msal-common/dist/response/AuthenticationResult";
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { Observable, of } from 'rxjs';
import { NgxPaginationModule } from 'ngx-pagination';
import { environment } from 'src/environments/environment';
import { SignalRService } from "./shared/services/signalR.service";
import { ResponseInterceptor } from './shared/interceptors/response.interceptor';
import { UserAuthInterceptor } from './shared/interceptors/user-auth.interceptor';
import { OirDialogComponent } from 'proceduralsystem-clientcomponents/components/oir-dialog/oir-dialog.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

export class TranslationHandlerService implements MissingTranslationHandler {
  /**
   * Missing translation handler.
   */
  public handle(params: MissingTranslationHandlerParams): Observable<string> {
    return of(params.key);
  }
}

/**
 * MSAL Angular retrieve tokens for authorizaion
 */
export function MSALInstanceFactory(
  config: AppConfigService
): PublicClientApplication {
  const msalConfig = environment.msalConfig;
  const endpoint = config.getValue("AzureAd");
  if (endpoint) {
    msalConfig.auth.clientId = endpoint.ClientId;
    msalConfig.auth.authority = `${endpoint.Instance}${endpoint.TenantId}`;
  }

  const msalInstance = new PublicClientApplication(msalConfig);

  // Account selection logic is app dependent. Adjust as needed for different use cases.
  const account = msalInstance.getActiveAccount();
  if (!account) {
    // Set active account on page load
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
    } else {
      // handle auth redirect/do all initial setup for msal
      msalInstance.addEventCallback((event) => {
        // set active account after redirect
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
          msalInstance.setActiveAccount(
            (event.payload as AuthenticationResult).account
          );
          window.location.reload();
        }
      });
    }
  }

  return msalInstance;
}

export function MSALInterceptorConfigFactory(
  config: AppConfigService
): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, string[]>();
  const apiEndpoint = config.getValue("ApiEndpoint");
  if (apiEndpoint) {
    protectedResourceMap.set(apiEndpoint.url, apiEndpoint.scopes);
  }
  const commsEndpoint = config.getValue("CommsEndpoint");
  if (commsEndpoint) {
    protectedResourceMap.set(commsEndpoint.url, commsEndpoint.scopes);
  }

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(
  config: AppConfigService
): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: { scopes: config.getValue("ApiEndpoint").scopes },
  };
}

@NgModule({
  imports: [
    BrowserModule,
    RouterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: TranslationHandlerService
      },
      defaultLanguage: 'en',
      isolate: false
    }),
    routing,
    BrowserAnimationsModule,
    NgxPaginationModule,
    ClientFrameworkModule
  ],
  declarations: [AppComponent, PageComponent],
  providers: [
    SignalRService,
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: HTTP_INTERCEPTORS, useClass: UserAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: (config: AppConfigService, injector: Injector) => async () =>
      {
        const initResult = await config.init().toPromise();
        const translateService = injector.get(TranslateService);
        /*
          1. TranslationService needs to be loaded on app startup,
            instant translations are required for navigation init (app.component & DL shared components).
          2. It's AppConfig dependent due to 'AppInitRequest' (user details fetch)
            and 'VersionNumber' (of translation document), so it can't be separate provider.
          3. Ensuring that primary language translations and secondary language file are fetched, so translations are available.
        */
        await Promise.all([
          translateService.get('en').toPromise(),
          translateService.reloadLang('ga').toPromise(),
        ]);

        return initResult;
      },
      deps: [AppConfigService, Injector],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
      deps: [AppConfigService],
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
      deps: [AppConfigService],
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
      deps: [AppConfigService],
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  entryComponents: [OirDialogComponent],
  bootstrap: [AppComponent]
})
export class AppModule {}
